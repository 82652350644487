/** @jsxImportSource theme-ui */
import React from 'react'
import { Container, Flex, ThemeUICSSObject } from 'theme-ui'
import type {
  ICollectionSection,
  IExternalProduct,
  IHero,
  IMediaSection,
  IProduct,
  ISection,
} from 'graphql-cms/types'
import { useWindowSize } from '~/hooks'
import { ISales } from 'basta-ai/types'
import {
  IMediaWithTextGrid,
  IMediaSlider,
  IRichText,
  IArchivesCollection,
} from 'graphql-cms/fragments/page'

import CollectionSection from './Collection'
import ProductSection from './Product'
import TextSection from './Text'
import MediaWithTextGrid from './MediaWithTextGrid'
import MediaSlider from './MediaSlider'
import ArchivesCollection from './ArchivesCollection'
import ContentHero from '../Hero'
import RichText from './RichText'

interface Props {
  section: ISection
  sales?: ISales[]
  removeMobilePadding?: boolean // Horizontal mobile padding
  sectionsContainerSx?: ThemeUICSSObject
}

interface SectionMediaProps {
  title: string
  description: string
  cta: unknown
  hideAct: boolean
  media: IMediaSection[]
  sales?: ISales[]
}

const SectionMedia: React.FC<SectionMediaProps> = ({
  title,
  description,
  cta,
  hideAct,
  media,
  sales,
}) => {
  const { width } = useWindowSize()

  const defaultMedia = <>Undefined type</>
  const mediaObj = {
    Product: (
      <ProductSection
        products={media as (IProduct | IExternalProduct)[]}
        sales={sales}
        title={title}
        description={description}
        hideActButton={hideAct}
      />
    ),
    ExternalProduct: (
      <ProductSection
        products={media as (IProduct | IExternalProduct)[]}
        sales={sales}
        title={title}
        description={description}
        hideActButton={hideAct}
      />
    ),
    Collection: (
      <CollectionSection
        collections={media as ICollectionSection[]}
        title={title}
        description={description}
      />
    ),
    Hero: (
      <ContentHero
        cta={cta}
        heading={(media[0] as IHero).heading}
        subheading={(media[0] as IHero).subheading}
        image={
          width < 500 && (media[0] as IHero)?.imageMobile
            ? (media[0] as IHero).imageMobile
            : (media[0] as IHero).image
        }
      />
    ),
    ContentTypeRichText: <RichText {...(media[0] as IRichText)} />,
    MediaWithTextGrid: (
      <MediaWithTextGrid {...(media[0] as IMediaWithTextGrid)} />
    ),
    MediaSlider: (
      <MediaSlider
        autoplaySpeed={(media[0] as IMediaSlider).autoplaySpeed}
        slidesToShowMobile={(media[0] as IMediaSlider).slidesToShowMobile}
        slidesToShowDesktop={(media[0] as IMediaSlider).slidesToShowDesktop}
        slides={(media[0] as IMediaSlider).slidesCollection?.items || []}
      />
    ),
    ArchivesCollection: (
      <ArchivesCollection {...(media[0] as IArchivesCollection)} />
    ),
  }

  return mediaObj[media[0].__typename] || defaultMedia
}

/* tslint:disable */
const ContentSection: React.FC<Props> = ({
  section,
  sales,
  removeMobilePadding = false,
  sectionsContainerSx = {},
}) => {
  const {
    title,
    description,
    richDescription,
    cta,
    hideActButton: hideAct = false,
  } = section
  const { items: media } = section.mediaCollection

  // @TODO: This component assumes that all media items have the same type, even
  // though the Contentful type allows them to be mixed. From a content
  // standpoint, though, it might make sense to have it this way. Gotta figure
  // out the types a little bit.

  return (
    <Flex
      backgroundColor="background"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '@media screen and (max-width: 52em)': {
          '[datatype="image-hero-mobile"]': {
            margin: '0 -5px',
            width: 'calc(100% + 10px)',
          },
          '[datatype="image-hero-mobile"] [datatype="text-container"]': {
            padding: '0 5px',
          },
        },
      }}
    >
      <Container
        sx={{
          padding: removeMobilePadding
            ? ['0px', null, '0px 64px']
            : media[0]?.__typename === 'MediaSlider' ||
              media[0]?.__typename === 'Hero'
            ? '0px !important'
            : ['0px 10px', null, '0px 64px'],
          ...sectionsContainerSx,
        }}
      >
        {media.length > 0 ? (
          <div>
            <SectionMedia
              title={title}
              description={description}
              cta={cta}
              hideAct={hideAct}
              media={media}
              sales={sales}
            />
          </div>
        ) : (
          <TextSection
            title={title}
            description={description}
            richDescription={richDescription}
          />
        )}
      </Container>
    </Flex>
  )
}

export default ContentSection
